import React, { useEffect, useState } from 'react'
import vilLogoRed from '../../../../../../assets/images/vi-busi.png'
import vilLogoBlack from '../../../../../../assets/images/logo-bg-black.jpg'
import { Spinner, Center, useTheme } from '@chakra-ui/react'
import { useAuthContext } from '../../../../../../context/auth.context'
import {
	useLoadRomsFormFieldListData,
	useLoadAllFormData,
} from '../../../../../../hooks/form.hooks'
import { loadImageBlob } from '../../../../../../utils/aws.util'
import { encode } from '../../../caf-crf-view/cafCrf.util'
import crfStyle from './coloCrfForm.module.css'
import ColoCofTermsConditionsNew from '../../ColoCofTermsConditions'

const ColoCrfForm = ({ formData, queueData, romsActivity, submittedForms }) => {
	const chakraTheme = useTheme()
	let componentRef = React.useRef()
	const [crfData, setcrfData] = useState(false)
	const [colocrfDataupdated, setUpdatedcrfData] = useState(null)
	const [imageData, setImageData] = useState(null)
	const [imageType, setImageType] = useState(null)
	const [custImageData, setCustImageData] = useState(null)
	const [custImageType, setCustImageType] = useState(null)
	const [acctMngSignImageData, setacctMngSignImageData] = useState(null)
	const [tableData, setTableData] = useState([])
	const {
		state: { authData },
	} = useAuthContext()
	let coloCrfContentView = ''

	const { mutate } = useLoadRomsFormFieldListData()
	const { mutate: loadAllData } = useLoadAllFormData()
	const fetchFieldListData = () => {
		mutate(
			{
				fieldList: {
					organization_id: authData.organization_id,
					account_id: authData.account_id,
					workforce_id: authData.workforce_id,
					form_id: formData.form_id,
				},
				fieldValues: {
					form_id: formData.form_id,
					form_transaction_id: formData.form_transaction_id
						? formData.form_transaction_id
						: formData.data_form_transaction_id,
					start_from: 0,
					limit_value: 50,
				},
			},
			{
				onSuccess: async data => {
					let finalFields = data
					setcrfData(false)
					setUpdatedcrfData(finalFields)
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
	}
	const fetchTableData = () => {
		loadAllData(
			{
				workflow_activity_id: queueData?.activity_id,
				form_id: romsActivity?.multi_submission_form_id,
				page_start: 0,
				page_limit: 50,
			},
			{
				onSuccess: async data => {
					if (data.length > 0) {
						let allInlineActivitiesArray = []
						for (const item of data) {
							if (!!item.activity_inline_data)
								allInlineActivitiesArray.push(item.activity_inline_data)
						}
						setTableData(allInlineActivitiesArray)
					}
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
	}
	useEffect(() => {
		setcrfData(true)
		fetchFieldListData()
		fetchTableData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		let data = colocrfDataupdated
		if (!!data) {
			if (!!data[344141]?.value) {
				// customer company seal
				let custCompanySeal = data[344141]?.value

				loadImageBlob(custCompanySeal, (data, type) => {
					setImageData(data)
					setImageType(type)
				})
			}
			if (!!data[343846]?.value) {
				// customer  sign
				let custCompanySeal = data[343846]?.value
				loadImageBlob(custCompanySeal, (data, type) => {
					setCustImageData(data)
					setCustImageType(type)
				})
			}
			if (!!data[343847]?.value) {
				// manager sign url
				let acctManagerSignUrl = data[343847]?.value
				loadImageBlob(acctManagerSignUrl, (data, type) => {
					setacctMngSignImageData(data)
				})
			}
		}
	}, [colocrfDataupdated])
	if (!!colocrfDataupdated) {
		let customerSignSplit =
			!!colocrfDataupdated[343846]?.value &&
			colocrfDataupdated[343846]?.value?.length > 0
				? colocrfDataupdated[343846]?.value.split(' ')
				: ''
		let firstCaps
		let secondBold
		let Signcss
		if (customerSignSplit.length > 0) {
			firstCaps = customerSignSplit['0'].slice(0, 1).toUpperCase()
			secondBold = customerSignSplit['1']
			Signcss = 'signate_img input_sign'
		} else {
			firstCaps = customerSignSplit
			secondBold = ''
			Signcss = 'signate_img input_sign under_line'
		}

		coloCrfContentView = (
			<div>
				<div
					style={{ textAlign: 'left', marginTop: '5px' }}
					className={crfStyle.tab_content2}
					ref={el => (componentRef = el)}
				>
					<page size='A4'>
						<div className={crfStyle.section2}>
							<div className={`${crfStyle.font8} ${crfStyle.pad_t30}`}>
								<div className={crfStyle.over_hid}>
									<div className={crfStyle.width63}>
										<p className={crfStyle.htext1}>
											<img src={vilLogoRed} alt='' /> Change Request Form -
											Colocation
										</p>
									</div>

									<div className={`${crfStyle.width30} ${crfStyle.flright}`}>
										<div className={crfStyle.caf}>
											<strong> COF ID </strong>
											<input
												className={crfStyle.form_col}
												type='text'
												name=''
												value={colocrfDataupdated[344176]?.value}
											/>
										</div>

										<div className={crfStyle.caf_inner}>
											<strong> Account Code</strong>
											<input
												className={`${crfStyle.form_col} ${crfStyle.no_top}`}
												type='text'
												name=''
												value={colocrfDataupdated[344177]?.value}
											/>
										</div>
									</div>
								</div>

								<div className={crfStyle.pad_t30}>
									<div className={`${crfStyle.flex} ${crfStyle.jcnt}`}>
										<strong>Company(Customer) Name</strong>
										<input
											className={`${crfStyle.form_col_input} ${crfStyle.width77}`}
											type='text'
											name=''
											value={colocrfDataupdated[343778]?.value}
										/>
									</div>
								</div>

								<div className={`${crfStyle.w_85} ${crfStyle.pad_t30}`}>
									<p className={`${crfStyle.sign} ${crfStyle.underline}`}>
										<strong style={{ color: 'black' }}>
											Authorised Signatory Details
										</strong>
									</p>
								</div>
								<div
									className={`${crfStyle.over_hid} ${crfStyle.auth} ${crfStyle.brdbtm} ${crfStyle.send}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width45} ${crfStyle.flex} ${crfStyle.send}`}
									>
										Name
										<input
											className={`${crfStyle.form_col} ${crfStyle.width85}`}
											type='text'
											name=''
											value={colocrfDataupdated[343780]?.value}
										/>
									</div>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width25} ${crfStyle.flex} ${crfStyle.send}`}
									>
										Contact No:
										<input
											className={`${crfStyle.form_col} ${crfStyle.w34w}`}
											type='text'
											name=''
											value={colocrfDataupdated[343781]?.value}
										/>
									</div>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width30} ${crfStyle.flex} ${crfStyle.send}`}
									>
										Email:
										<input
											className={`${crfStyle.form_col} ${crfStyle.width80}`}
											type='text'
											name=''
											value={colocrfDataupdated[343782]?.value}
										/>
									</div>
								</div>

								<div
									className={`${crfStyle.check} ${crfStyle.flex} ${crfStyle.custype} ${crfStyle.f12} ${crfStyle.mb20} ${crfStyle.pad_t30}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width18} ${crfStyle.pad_7t} ${crfStyle.underline}`}
									>
										<strong>Type of change order </strong>
									</div>
									<div
										className={`${crfStyle.ctinf} ${crfStyle.space}-${crfStyle.between}}`}
									>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width25} ${crfStyle.f10} `}
										>
											{colocrfDataupdated[343783]?.value
												?.split('|')
												?.indexOf('Upgrade') >= 0 ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Upgrade
										</div>{' '}
										<div
											className={`${crfStyle.fleft} ${crfStyle.width22} ${crfStyle.f10} `}
										>
											{colocrfDataupdated[343783]?.value
												?.split('|')
												?.indexOf('Downgrade') >= 0 ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Downgrade
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width28} ${crfStyle.f10} `}
										>
											{colocrfDataupdated[343783]?.value
												?.split('|')
												?.indexOf('PO Extension-Upgrade & Downgrade') >= 0 ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											PO Extension-Upgrade & Downgrade
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width25} ${crfStyle.f10} `}
										>
											{colocrfDataupdated[343783]?.value
												?.split('|')
												?.indexOf('Contract Extension') >= 0 ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Contract Extension
										</div>
									</div>
								</div>

								<div
									className={`${crfStyle.check} ${crfStyle.flex} ${crfStyle.custype} ${crfStyle.f12} ${crfStyle.mb20}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width18} ${crfStyle.pad_7t} ${crfStyle.underline}`}
									></div>
									<div
										className={`${crfStyle.ctinf} ${crfStyle.space}-${crfStyle.between} `}
									>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width25} ${crfStyle.f10} `}
										>
											{colocrfDataupdated[343783]?.value
												?.split('|')
												?.indexOf('Billing Address Change') >= 0 ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Billing Address Change
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width22} ${crfStyle.f10} `}
										>
											{colocrfDataupdated[343783]?.value
												?.split('|')
												?.indexOf('Billing Periodicity Change') >= 0 ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Billing Periodicity Change
										</div>
										{/* <div
											className={`${crfStyle.fleft} ${crfStyle.width28} ${crfStyle.f10} `}
										>
											{colocrfDataupdated[343783]?.value
												?.split('|')
												?.indexOf('Billing in (Payment Mode) Change') >= 0 ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Billing in (Payment Mode) Change
										</div> */}
										<div
											className={`${crfStyle.fleft} ${crfStyle.width25} ${crfStyle.f10} `}
										>
											{colocrfDataupdated[343783]?.value
												?.split('|')
												?.indexOf('Customer Name Change') >= 0 ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Customer Name Change
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width25} ${crfStyle.f10} `}
										>
											{colocrfDataupdated[343783]?.value
												?.split('|')
												?.indexOf('Customer Contact Change') >= 0 ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Customer Contact Change
										</div>
									</div>
								</div>

								<div
									className={`${crfStyle.check} ${crfStyle.flex} ${crfStyle.custype} ${crfStyle.f12} ${crfStyle.mb20}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width18} ${crfStyle.pad_7t} ${crfStyle.underline}`}
									></div>
									<div
										className={`${crfStyle.ctinf} ${crfStyle.space}-${crfStyle.between} `}
									>
										{/* <div
											className={`${crfStyle.fleft} ${crfStyle.width22} ${crfStyle.f10} `}
										>
											{colocrfDataupdated[343783]?.value
												?.split('|')
												?.indexOf('Account Contact change') >= 0 ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Account Contact change
										</div> */}
										{/* <div
											className={`${crfStyle.fleft} ${crfStyle.width28} ${crfStyle.f10} `}
										>
											{colocrfDataupdated[343783]?.value
												?.split('|')
												?.indexOf('Revenue Circle Change') >= 0 ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Revenue Circle Change
										</div> */}
										<div
											className={`${crfStyle.fleft} ${crfStyle.width25} ${crfStyle.f10} `}
										>
											{colocrfDataupdated[343783]?.value
												?.split('|')
												?.indexOf('Billing Customer Name Change') >= 0 ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Billing Customer Name Change
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width25} ${crfStyle.f10} `}
										>
											{colocrfDataupdated[343783]?.value
												?.split('|')
												?.indexOf('Authorized Signatory Change') >= 0 ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Authorized Signatory Change
										</div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width22} ${crfStyle.f10} `}
										>
											{colocrfDataupdated[343783]?.value
												?.split('|')
												?.indexOf('Price Revision') >= 0 ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Price Revision
										</div>
									</div>
								</div>

								{/* <div
									className={`${crfStyle.check} ${crfStyle.flex} ${crfStyle.custype} ${crfStyle.f12} ${crfStyle.mb20}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width18} ${crfStyle.pad_7t} ${crfStyle.underline}`}
									></div>
									<div
										className={`${crfStyle.ctinf} ${crfStyle.space}-${crfStyle.between} `}
									>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width25} ${crfStyle.f10} `}
										></div>
										<div
											className={`${crfStyle.fleft} ${crfStyle.width25} ${crfStyle.f10} `}
										></div>
									</div>
								</div> */}

								<div className={crfStyle.graybghed}>
									Bill periodicity change
								</div>

								<div
									className={`${crfStyle.check} ${crfStyle.flex} ${crfStyle.custype} ${crfStyle.f12} ${crfStyle.mb20} ${crfStyle.pad_t10}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width20} ${crfStyle.pad_7t} `}
									>
										Existing Billing periodicity
									</div>
									<div className={crfStyle.ctinf}>
										<div className={`${crfStyle.fleft} ${crfStyle.width15}`}>
											{colocrfDataupdated[343785]?.value === 'Monthly' ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Monthly
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width15}`}>
											{colocrfDataupdated[343785]?.value === 'Quarterly' ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Quarterly
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width15}`}>
											{colocrfDataupdated[343785]?.value === 'Half Yearly' ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Half Yearly
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width15}`}>
											{colocrfDataupdated[343785]?.value === 'Annually' ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Annually
										</div>
									</div>
								</div>

								<div
									className={`${crfStyle.check} ${crfStyle.flex} ${crfStyle.f12} ${crfStyle.mb30}`}
								>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width20} ${crfStyle.pad_7t} `}
									>
										Revised Billing periodicity
									</div>
									<div className={crfStyle.ctinf}>
										<div className={`${crfStyle.fleft} ${crfStyle.width15}`}>
											{colocrfDataupdated[343786]?.value === 'Monthly' ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Monthly
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width15}`}>
											{colocrfDataupdated[343786]?.value === 'Quarterly' ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Quarterly
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width15}`}>
											{colocrfDataupdated[343786]?.value === 'Half Yearly' ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Half Yearly
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width15}`}>
											{colocrfDataupdated[343786]?.value === 'Annually' ? (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													checked
												/>
											) : (
												<input
													className={crfStyle.form_col}
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Annually
										</div>
									</div>
								</div>

								<div className={crfStyle.graybghed}>
									Company (Customer) Name change
								</div>

								<p
									className={`${crfStyle.auth} ${crfStyle.jcnt} ${crfStyle.pad_t10} `}
								>
									Existing Name of the Customer (company)
									<input
										className={`${crfStyle.form_col} ${crfStyle.width60}`}
										type='text'
										name=''
										value={colocrfDataupdated[343788]?.value}
									/>
								</p>
								<p className={`${crfStyle.auth} ${crfStyle.jrcnt}`}>
									<input
										className={`${crfStyle.form_col} ${crfStyle.width60} ${crfStyle.flright} `}
										type='text'
										name=''
										value=''
									/>
								</p>

								<p className={`${crfStyle.auth} ${crfStyle.jcnt}`}>
									Revised Name of the Customer (company)
									<input
										className={`${crfStyle.form_col} ${crfStyle.width60}`}
										type='text'
										name=''
										value={colocrfDataupdated[343789]?.value}
									/>
								</p>
								<p className={`${crfStyle.auth} ${crfStyle.jrcnt}`}>
									<input
										className={`${crfStyle.form_col} ${crfStyle.width60} ${crfStyle.flright} `}
										type='text'
										name=''
										value=''
									/>
								</p>
								<div className={crfStyle.signateNew}>
									{firstCaps !== '' ? (
										<div style={{ padding: '0px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '0px' }} className='signate_img'>
											<img
												src={
													imageData !== null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</page>

					<page size='A4'>
						<div className={crfStyle.section2}>
							<div className={crfStyle.font8}>
								<div className={crfStyle.graybghed}>
									Customer Contact Change
								</div>
								<p className={`${crfStyle.auth} ${crfStyle.jcnt}`}>
									Existing Technical, Contact Name
									<input
										className={`${crfStyle.form_col} ${crfStyle.width70}`}
										type='text'
										name=''
										value={colocrfDataupdated[343791]?.value}
									/>
								</p>
								<div
									className={`${crfStyle.auth} ${crfStyle.margin0} ${crfStyle.jcnt} `}
								>
									<span class=''>Existing Address</span>
									<input
										className={`${crfStyle.form_col} ${crfStyle.width70}`}
										type='text'
										name=''
										value={colocrfDataupdated[343792]?.value}
									/>
								</div>
								<div
									className={`${crfStyle.auth} ${crfStyle.pad_b5} ${crfStyle.jcnt} `}
								>
									<span class=''> </span>
									<input
										className={`${crfStyle.form_col} ${crfStyle.width70}`}
										type='text'
										name=''
										value=''
									/>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.over_hid}`}>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width54} ${crfStyle.flex} ${crfStyle.jcnt}`}
									>
										Existing Telephone
										<input
											className={`${crfStyle.form_col} ${crfStyle.width43}`}
											type='text'
											name=''
											value={colocrfDataupdated[343793]?.value}
										/>
									</div>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width50} ${crfStyle.flex} ${crfStyle.jcnt} ${crfStyle.jcend}`}
									>
										<span className={crfStyle.padr5}>Mobile No. / Alt No.</span>
										<input
											className={`${crfStyle.form_col} ${crfStyle.width50}`}
											type='text'
											name=''
											value={colocrfDataupdated[343794]?.value}
										/>
									</div>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.over_hid}`}>
									<div
										className={`${crfStyle.fleft} ${crfStyle.fullwidth} ${crfStyle.flex} ${crfStyle.jcnt} ${crfStyle.brdbonly}`}
									>
										Existing Contact Email Id
										<input
											className={`${crfStyle.form_col} ${crfStyle.width70}`}
											type='text'
											name=''
											value={colocrfDataupdated[343795]?.value}
										/>
									</div>
								</div>

								<div className={crfStyle.padt20}>
									<p className={`${crfStyle.auth} ${crfStyle.jcnt}`}>
										Revised Technical, Contact Name
										<input
											className={`${crfStyle.form_col} ${crfStyle.width70}`}
											type='text'
											name=''
											value={colocrfDataupdated[343796]?.value}
										/>
									</p>
								</div>
								<div
									className={`${crfStyle.auth} ${crfStyle.margin0} ${crfStyle.jcnt} `}
								>
									<span class=''>Revised Address</span>
									<input
										className={`${crfStyle.form_col} ${crfStyle.width70}`}
										type='text'
										name=''
										value={colocrfDataupdated[343797]?.value}
									/>
								</div>
								<div
									className={`${crfStyle.auth} ${crfStyle.pad_b5} ${crfStyle.jcnt} `}
								>
									<span class=''> </span>
									<input
										className={`${crfStyle.form_col} ${crfStyle.width70}`}
										type='text'
										name=''
										value=''
									/>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.over_hid}`}>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width54} ${crfStyle.flex} ${crfStyle.jcnt}`}
									>
										Revised Telephone
										<input
											className={`${crfStyle.form_col} ${crfStyle.width43}`}
											type='text'
											name=''
											value={colocrfDataupdated[343798]?.value}
										/>
									</div>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width50} ${crfStyle.flex} ${crfStyle.jcnt} ${crfStyle.jcend}`}
									>
										<span className={crfStyle.padr5}>Mobile No. / Alt No.</span>
										<input
											className={`${crfStyle.form_col} ${crfStyle.width50}`}
											type='text'
											name=''
											value={colocrfDataupdated[343799]?.value}
										/>
									</div>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.over_hid}`}>
									<div
										className={`${crfStyle.fleft} ${crfStyle.fullwidth} ${crfStyle.flex} ${crfStyle.jcnt} ${crfStyle.brdbonly}`}
									>
										Revised Contact Email Id
										<input
											className={`${crfStyle.form_col} ${crfStyle.width70}`}
											type='text'
											name=''
											value={colocrfDataupdated[344030]?.value}
										/>
									</div>
								</div>
								<div className={crfStyle.graybghed}>Billing Address Change</div>
								<div className={crfStyle.padt10}>
									<p className={`${crfStyle.auth} ${crfStyle.jcnt}`}>
										Contact Person Name Change
										<input
											className={`${crfStyle.form_col} ${crfStyle.width70}`}
											type='text'
											name=''
											value={colocrfDataupdated[343801]?.value}
										/>
									</p>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.over_hid}`}>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width58} ${crfStyle.flex} ${crfStyle.jcnt}`}
									>
										Designation
										<input
											className={`${crfStyle.form_col} ${crfStyle.width48}`}
											type='text'
											name=''
											value={colocrfDataupdated[343802]?.value}
										/>
									</div>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width40} ${crfStyle.flex} ${crfStyle.jcnt} ${crfStyle.padl30}`}
									>
										<span className={crfStyle.padr}>Dept. Name</span>
										<input
											className={`${crfStyle.form_col} ${crfStyle.width70}`}
											type='text'
											name=''
											value={colocrfDataupdated[343803]?.value}
										/>
									</div>
								</div>
								<div
									className={`${crfStyle.auth} ${crfStyle.margin0} ${crfStyle.jcnt} `}
								>
									<span class=''>Address</span>
									<input
										className={`${crfStyle.form_col} ${crfStyle.width70}`}
										type='text'
										name=''
										value={colocrfDataupdated[343804]?.value}
									/>
								</div>
								<div
									className={`${crfStyle.auth} ${crfStyle.pad_b5} ${crfStyle.jcnt} `}
								>
									<span class=''> City/Village/Post Office</span>
									<input
										className={`${crfStyle.form_col} ${crfStyle.width70}`}
										type='text'
										name=''
										value={colocrfDataupdated[343805]?.value}
									/>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.over_hid}`}>
									<div
										className={`${crfStyle.fleft} ${crfStyle.flex} ${crfStyle.width30} ${crfStyle.jcnt} ${crfStyle.brdbonly}`}
									>
										State
										<input
											className={`${crfStyle.form_col} ${crfStyle.width60}`}
											type='text'
											name=''
											value={colocrfDataupdated[343806]?.value}
										/>
									</div>
									<div
										className={`${crfStyle.fleft} ${crfStyle.flex} ${crfStyle.width15} ${crfStyle.jcnt} ${crfStyle.padl10}`}
									>
										Pin
										<input
											className={`${crfStyle.form_col} ${crfStyle.width60}`}
											type='text'
											name=''
											value={colocrfDataupdated[343807]?.value}
										/>
									</div>
									<div
										className={`${crfStyle.fleft} ${crfStyle.flex} ${crfStyle.width30} ${crfStyle.jcnt} ${crfStyle.padl10}`}
									>
										Telephone
										<input
											className={`${crfStyle.form_col} ${crfStyle.width60}`}
											type='text'
											name=''
											value={colocrfDataupdated[343808]?.value}
										/>
									</div>
								</div>
								<div className={crfStyle.signateNew}>
									{firstCaps !== '' ? (
										<div style={{ padding: '0px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '0px' }} className='signate_img'>
											<img
												src={
													imageData !== null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</page>

					{/* <page size='A4'>
						<div className={crfStyle.section2}>
							<div className={crfStyle.font8}>
								 <div className={crfStyle.graybghed}>Account Contact Change</div>
								<div className={crfStyle.padt10}>
									<p className={`${crfStyle.auth} ${crfStyle.jcnt}`}>
										New Account Manger Name
										<input
											className={`${crfStyle.form_col} ${crfStyle.width70}`}
											type='text'
											name=''
											value={colocrfDataupdated[343810]?.value}
										/>
									</p>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.over_hid}`}>
									<div
										className={`${crfStyle.fleft} ${crfStyle.fullwidth} ${crfStyle.flex} ${crfStyle.brdbonly}`}
									>
										Existing Contact Email Id
										<input
											className={`${crfStyle.form_col} ${crfStyle.width40} ${crfStyle.marl93} `}
											type='text'
											name=''
											value={colocrfDataupdated[343810]?.value}
										/>
									</div>
								</div>

								<div className={`${crfStyle.auth} ${crfStyle.over_hid}`}>
									<div
										className={`${crfStyle.fleft} ${crfStyle.fullwidth} ${crfStyle.flex} `}
									>
										New Account manager <br />
										Telephone/Mobile No.
										<input
											className={`${crfStyle.form_col} ${crfStyle.width40} ${crfStyle.marl100} `}
											type='text'
											name=''
											value={colocrfDataupdated[343811]?.value}
										/>
									</div>
								</div>
								<div class=''>
									<p className={`${crfStyle.auth} ${crfStyle.jcnt}`}>
										New Channel Partner Name
										<input
											className={`${crfStyle.form_col} ${crfStyle.width70}`}
											type='text'
											name=''
											value={colocrfDataupdated[343814]?.value}
										/>
									</p>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.over_hid}`}>
									<div
										className={`${crfStyle.fleft} ${crfStyle.fullwidth} ${crfStyle.flex} `}
									>
										New Channel partner Code
										<input
											className={`${crfStyle.form_col} ${crfStyle.width40} ${crfStyle.marl83} `}
											type='text'
											name=''
											value={colocrfDataupdated[343815]?.value}
										/>
									</div>
								</div>
								<div className={crfStyle.graybghed}>Revenue Circle Change</div>
								<div className={`${crfStyle.auth} ${crfStyle.over_hid}`}>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width50} ${crfStyle.flex} ${crfStyle.jcntl} ${crfStyle.pad_l10}`}
									>
										<span className={crfStyle.padr5}>
											Existing Revenue Circle
										</span>
										<input
											className={`${crfStyle.form_col} ${crfStyle.width40}`}
											type='text'
											name=''
											value={colocrfDataupdated[343869]?.value}
										/>
									</div>
									<div
										className={`${crfStyle.fleft} ${crfStyle.width50} ${crfStyle.flex} ${crfStyle.jcntl} ${crfStyle.pad_l10}`}
									>
										<span className={crfStyle.padr5}>New Reveneue Circle </span>
										<input
											className={`${crfStyle.form_col} ${crfStyle.width40}`}
											type='text'
											name=''
											value={colocrfDataupdated[343870]?.value}
										/>
									</div>
								</div> 

								<div className={crfStyle.graybghed}>
									Authorized Signatory Change
								</div>

								<div className={crfStyle.padt10}>
									<p className={`${crfStyle.auth} ${crfStyle.jcnt}`}>
										New Authorized Signatory Name
										<input
											className={`${crfStyle.form_col} ${crfStyle.width70}`}
											type='text'
											name=''
											value={colocrfDataupdated[343818]?.value}
										/>
									</p>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.over_hid}`}>
									<div
										className={`${crfStyle.fleft} ${crfStyle.fullwidth} ${crfStyle.flex} `}
									>
										New Authorized Signatory <br />
										Contact Number
										<input
											className={`${crfStyle.form_col} ${crfStyle.width40} ${crfStyle.marl87} `}
											type='text'
											name=''
											value={colocrfDataupdated[343819]?.value}
										/>
									</div>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.over_hid}`}>
									<div
										className={`${crfStyle.fleft} ${crfStyle.fullwidth} ${crfStyle.flex} ${crfStyle.brdbonly}`}
									>
										New authorized signatory Email
										<input
											className={`${crfStyle.form_col} ${crfStyle.width40} ${crfStyle.marl60} `}
											type='text'
											name=''
											value={colocrfDataupdated[343820]?.value}
										/>
									</div>
								</div>
								<div className={crfStyle.signateNew}>
									{firstCaps !== '' ? (
										<div style={{ padding: '0px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '0px' }} className='signate_img'>
											<img
												src={
													imageData !== null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</page> */}

					<page size='A4'>
						<div className={crfStyle.section2}>
							<div className={crfStyle.font8}>
								<div className={crfStyle.graybghed}>
									Authorized Signatory Change
								</div>

								<div className={crfStyle.padt10}>
									<p className={`${crfStyle.auth} ${crfStyle.jcnt}`}>
										New Authorized Signatory Name
										<input
											className={`${crfStyle.form_col} ${crfStyle.width70}`}
											type='text'
											name=''
											value={colocrfDataupdated[343818]?.value}
										/>
									</p>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.over_hid}`}>
									<div
										className={`${crfStyle.fleft} ${crfStyle.fullwidth} ${crfStyle.flex} `}
									>
										New Authorized Signatory <br />
										Contact Number
										<input
											className={`${crfStyle.form_col} ${crfStyle.width40} ${crfStyle.marl87} `}
											type='text'
											name=''
											value={colocrfDataupdated[343819]?.value}
										/>
									</div>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.over_hid}`}>
									<div
										className={`${crfStyle.fleft} ${crfStyle.fullwidth} ${crfStyle.flex} ${crfStyle.brdbonly}`}
									>
										New authorized signatory Email
										<input
											className={`${crfStyle.form_col} ${crfStyle.width40} ${crfStyle.marl60} `}
											type='text'
											name=''
											value={colocrfDataupdated[343820]?.value}
										/>
									</div>
								</div>

								<div className={crfStyle.graybghed}>
									UPGRADE & DOWNGRADE & PRICE REVISION (REFER TO BOQ)
								</div>

								<div className={`${crfStyle.over_hid} ${crfStyle.referbo}`}>
									<div className={`${crfStyle.fullwidth} ${crfStyle.flex}`}>
										<div className={crfStyle.width25}>Old One Time Charges</div>
										<input
											className={`${crfStyle.form_col} ${crfStyle.wid45} ${crfStyle.inputext_brd_list} ${crfStyle.text}-${crfStyle.center}`}
											type='text'
											name=''
											value={colocrfDataupdated[343822]?.value}
										/>
									</div>
								</div>

								<div className={`${crfStyle.over_hid} ${crfStyle.referbo}`}>
									<div className={`${crfStyle.fullwidth} ${crfStyle.flex}`}>
										<div className={crfStyle.width25}>
											Old Annual Recurring Charges
										</div>
										<input
											className={`${crfStyle.form_col} ${crfStyle.wid45} ${crfStyle.inputext_brd_list} ${crfStyle.text}-${crfStyle.center}`}
											type='text'
											name=''
											value={colocrfDataupdated[343823]?.value}
										/>
									</div>
								</div>
								<div className={`${crfStyle.over_hid} ${crfStyle.referbo}`}>
									<div className={`${crfStyle.fullwidth} ${crfStyle.flex}`}>
										<div className={crfStyle.width25}>
											Modified One Time Charges
										</div>
										<input
											className={`${crfStyle.form_col} ${crfStyle.wid45} ${crfStyle.inputext_brd_list} ${crfStyle.text}-${crfStyle.center}`}
											type='text'
											name=''
											value={colocrfDataupdated[343825]?.value}
										/>
									</div>
								</div>
								<div className={`${crfStyle.over_hid} ${crfStyle.referbo}`}>
									<div className={`${crfStyle.fullwidth} ${crfStyle.flex}`}>
										<div className={crfStyle.width25}>
											Modified Annual Recurring Charges
										</div>
										<input
											className={`${crfStyle.form_col} ${crfStyle.wid45} ${crfStyle.inputext_brd_list} ${crfStyle.text}-${crfStyle.center}`}
											type='text'
											name=''
											value={colocrfDataupdated[343826]?.value}
										/>
										<div className={`${crfStyle.mLeft}`}>*Exclusive of tax</div>
									</div>
								</div>

								<div className={`${crfStyle.over_hid} ${crfStyle.referbo}`}>
									<span>Comments / Special Considerations:</span>
									<textarea
										className={`${crfStyle.form_col} ${crfStyle.inputext_brd_list} ${crfStyle.areatext} `}
										placeholder='Comments / Special Considerations:'
									>
										{colocrfDataupdated[343827]?.value}
									</textarea>
								</div>

								<div className={crfStyle.graybghed}>
									PO EXTENSION WITH UPGRADE/DOWNGRADE/PRICE REVISION (REFER TO
									BOQ)
								</div>

								<div className={`${crfStyle.auth} ${crfStyle.jcnt}`}>
									<span>Old PO Number</span>
									<input
										className={`${crfStyle.form_col} ${crfStyle.width75}`}
										type='text'
										name=''
										value={colocrfDataupdated[343829]?.value}
									/>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.jcnt}`}>
									<span>New PO Number</span>
									<input
										className={`${crfStyle.form_col} ${crfStyle.width75}`}
										type='text'
										name=''
										value={colocrfDataupdated[343830]?.value}
									/>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.jcnt}`}>
									<span>Expired PO Date</span>
									<input
										className={`${crfStyle.form_col} ${crfStyle.width75}`}
										type='text'
										name=''
										value={colocrfDataupdated[343876]?.value}
									/>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.jcnt}`}>
									<span>New PO Date</span>
									<input
										className={`${crfStyle.form_col} ${crfStyle.width75}`}
										type='text'
										name=''
										value={colocrfDataupdated[343875]?.value}
									/>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.jcnt}`}>
									<span>Old One Time Charges</span>
									<input
										className={`${crfStyle.form_col} ${crfStyle.width75}`}
										type='text'
										name=''
										value={colocrfDataupdated[343831]?.value}
									/>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.jcnt}`}>
									<span>Old Annual Recurring Charges</span>
									<input
										className={`${crfStyle.form_col} ${crfStyle.width75}`}
										type='text'
										name=''
										value={colocrfDataupdated[343832]?.value}
									/>
								</div>
								<div className={`${crfStyle.auth} ${crfStyle.jcnt}`}>
									<span>Modified One Time Charges</span>
									<input
										className={`${crfStyle.form_col} ${crfStyle.width75}`}
										type='text'
										name=''
										value={colocrfDataupdated[343834]?.value}
									/>
								</div>

								<div
									className={`${crfStyle.auth} ${crfStyle.jcnt} ${crfStyle.pad_t30} `}
								>
									<span>Modified Annual Recurring Charges</span>
									<input
										className={`${crfStyle.form_col} ${crfStyle.width75}`}
										type='text'
										name=''
										value={colocrfDataupdated[343835]?.value}
									/>
								</div>

								{/* <div className={`${crfStyle.auth} ${crfStyle.jcnt}`}>
									<span>Modified Comments/Special Considerations</span>
									<textarea
										className={`${crfStyle.form_col} ${crfStyle.inputext_brd_list} ${crfStyle.areatext} `}
										placeholder='Modified Comments/Special Considerations:'
									>
										{colocrfDataupdated[343836]?.value}
									</textarea>
								</div> */}
								<div className={`${crfStyle.over_hid} ${crfStyle.referbo}`}>
									<span>Comments / Special Considerations:</span>
									<textarea
										className={`${crfStyle.form_col} ${crfStyle.inputext_brd_list} ${crfStyle.areatext} `}
										placeholder='Comments / Special Considerations:'
									>
										{colocrfDataupdated[343837]?.value}
									</textarea>
								</div>
								<div className={crfStyle.signateNew}>
									{firstCaps !== '' ? (
										<div style={{ padding: '0px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '0px' }} className='signate_img'>
											<img
												src={
													imageData !== null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</page>

					{/*<page size='A4'>
						<div className={crfStyle.section2}>
							<div className={crfStyle.font12}>
								<div className={crfStyle.graybghed}>Terms and Conditions</div>

								<div className={crfStyle.terns}>
									a.) Customer is responsible for conducting the UAT and provide
									service acceptance within 2 days post receipt of delivery
									confirmation from VITSL. <br /> <br />
									<br />
									b) VITSL reserves the right to cancel the services or start
									billing of the the service in case of service acceptance not
									received from customer within 2 days of delivery. <br />{' '}
									<br />
									<br />
									c) Early Termination charges: If customer terminates the
									service prior to the end of the committed contract period (as
									mentioned in the COF) then customer shall be liable to pay
									100% early termination charges for the remaining period of the
									contract. <br />
									<br />
									<br />
									d) Customer should provide a minimum 30 days notice period
									before terminating entire/any part of the service <br />{' '}
									<br />
									<br />
									e) In the event the term or usage of a service extends beyond
									the agreed expiry date or termination date of this COF, then
									the term and conditions of this COF shall be extended to the
									expiration /termination of the applicable services. Customer
									shall be bound to make payment of during the extended period
									as per last agreed pricing terms. Either Party shall have a
									right to terminate a service after giving prior written notice
									of thirty (30) days’ notice in advance.
									<br /> <br />
									<br />
									<strong> Note- </strong> Customer will not be able to avail
									GST credit if the GSTIN number provided is not the same as
									place of supply (where the data center services are
									implemented).
								</div>
								<div className={crfStyle.signateNew}>
									{firstCaps !== '' ? (
										<div style={{ padding: '0px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '0px' }} className='signate_img'>
											<img
												src={
													imageData !== null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</page>*/}

					<page size='A4'>
						<div className={`${crfStyle.section2} ${crfStyle.font8}`}>
							<div class=''></div>
							<div
								className={`${crfStyle.over_hid} ${crfStyle.pad_t20} ${crfStyle.apsign} `}
							>
								<div
									className={`${crfStyle.fleft} ${crfStyle.width58p} ${crfStyle.pad_t10} `}
								>
									<p className={crfStyle.marb5}>
										Applicant's Name / Authorised Signatory's Name{' '}
									</p>
									<p className={crfStyle.margin0}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.width97p} ${crfStyle.inputext} `}
											type='text'
											name=''
											value={colocrfDataupdated[343838]?.value}
										/>
									</p>
									<div className={`${crfStyle.flex} ${crfStyle.pad_t30}`}>
										<div className={`${crfStyle.fleft} ${crfStyle.width40}`}>
											Designation <br />
											<input
												className={`${crfStyle.form_col} ${crfStyle.width97p} ${crfStyle.inputext} ${crfStyle.design}`}
												type='text'
												name=''
												value={colocrfDataupdated[343839]?.value}
											/>
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width30}`}>
											Place <br />
											<input
												className={`${crfStyle.form_col} ${crfStyle.width97p} ${crfStyle.inputext} ${crfStyle.design}`}
												type='text'
												name=''
												value={colocrfDataupdated[343840]?.value}
											/>
										</div>
										<div className={`${crfStyle.fleft} ${crfStyle.width30}`}>
											Date and Time <br />
											<input
												className={`${crfStyle.form_col} ${crfStyle.width97p} ${crfStyle.inputext} ${crfStyle.design}`}
												type='text'
												name=''
												value={colocrfDataupdated[343841]?.value}
											/>
										</div>
									</div>
								</div>
								<div className={`${crfStyle.flright} ${crfStyle.width35}`}>
									<div className={crfStyle.signate}>
										{firstCaps !== '' ? (
											<div className={Signcss}>
												{firstCaps + '  .  '}
												<span className='under_line'>{secondBold}</span>
											</div>
										) : (
											''
										)}
										Signature of the Customer / Authorised signatory within this
										box only (In case of institutional, please affix
										office/company seal)
									</div>
								</div>
							</div>
							<div className={`${crfStyle.over_hid} ${crfStyle.pad_t30}`}>
								<div
									className={`${crfStyle.fleft} ${crfStyle.width58p} ${crfStyle.pad_t10} `}
								>
									<p className={crfStyle.marb5}>Account Manager Name</p>
									<p className={crfStyle.margin0}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.width97p} ${crfStyle.inputext} `}
											type='text'
											name=''
											value={colocrfDataupdated[343842]?.value}
										/>
									</p>
									<p className={`${crfStyle.marb5} ${crfStyle.mt12px}`}>
										Account Manager Cluster Office
									</p>
									<p className={crfStyle.margin0}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.width97p} ${crfStyle.inputext} `}
											type='text'
											name=''
											value={colocrfDataupdated[343843]?.value}
										/>
									</p>
									<p className={`${crfStyle.marb5} ${crfStyle.mt12px}`}>
										Channel Partner Name
									</p>
									<p className={crfStyle.margin0}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.width50} ${crfStyle.inputext_brd_list} `}
											type='text'
											name=''
											value={colocrfDataupdated[343844]?.value}
										/>
									</p>
									<p className={`${crfStyle.marb5} ${crfStyle.mt12px}`}>
										Channel Partner Code
									</p>
									<p className={crfStyle.margin0}>
										<input
											className={`${crfStyle.form_col} ${crfStyle.width97p} ${crfStyle.inputext} `}
											type='text'
											name=''
											value={colocrfDataupdated[343845]?.value}
										/>
									</p>
								</div>
								<div
									className={`${crfStyle.flright} ${crfStyle.width35} ${crfStyle.signrigt} `}
								>
									<div className={crfStyle.signate}>
										{acctMngSignImageData !== null &&
										acctMngSignImageData !== '' ? (
											<div
												className='signate_img input_sign'
												style={{ marginBottom: '13px' }}
											>
												<img
													src={
														acctMngSignImageData !== null
															? 'data:' +
															  imageType +
															  ';base64,' +
															  encode(acctMngSignImageData)
															: ''
													}
													alt={'Account Manager Sign'}
												/>
											</div>
										) : (
											''
										)}
										Signature of the Account Manager
									</div>
								</div>
							</div>
							<div className={crfStyle.signateNew}>
								{firstCaps !== '' ? (
									<div style={{ padding: '0px' }} className={Signcss}>
										{firstCaps + '  .  '}
										<span className='under_line'>{secondBold}</span>
									</div>
								) : (
									''
								)}
								{imageData !== null && imageData !== '' ? (
									<div style={{ padding: '0px' }} className='signate_img'>
										<img
											src={
												imageData !== null
													? 'data:' + imageType + ';base64,' + encode(imageData)
													: ''
											}
											alt={'Company Seal'}
										/>
									</div>
								) : (
									''
								)}
							</div>
							<div className={crfStyle.limite}>
								Vodafone Idea Technology Solutions Limited (VITSL)
							</div>
						</div>
					</page>
					<ColoCofTermsConditionsNew
						isCrf={true}
						firstCaps={firstCaps}
						Signcss={Signcss}
						secondBold={secondBold}
						imageData={imageData}
						imageType={imageType}
					/>
				</div>
			</div>
		)
	} else {
		coloCrfContentView = crfData ? (
			<div>
				<Center p={2} my={1}>
					<Spinner
						thickness='4px'
						speed='0.65s'
						emptyColor={chakraTheme.colors.secondary}
						color={chakraTheme.colors.brand[800]}
						size='md'
					/>
				</Center>
			</div>
		) : (
			<div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
				{' '}
				CAF Form not submitted yet.
			</div>
		)
	}
	return <div>{coloCrfContentView}</div>
}

export default ColoCrfForm
